import { render, staticRenderFns } from "./NewReport.vue?vue&type=template&id=dad81204&scoped=true&"
import script from "./NewReport.vue?vue&type=script&lang=js&"
export * from "./NewReport.vue?vue&type=script&lang=js&"
import style0 from "./NewReport.vue?vue&type=style&index=0&id=dad81204&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dad81204",
  null
  
)

/* custom blocks */
import block0 from "@/core/i18n-common.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fhtml%2Ffrontend%2Fsrc%2Fmodules%2Freports%2FNewReport.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports