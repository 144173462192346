<template>
  <main id="reporting-view">
    <div class="card campaigns-card">
      <div class="card__content">
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title"><strong>{{ $t('preview') }}:</strong> {{ reportData.name }} {{ reportDates }}</h4>
          </div>
          <div class="card__header-actions">
            <button class="btn" @click.prevent="fetchReport" v-if="reportData.url && reportData.url !== ''">
              <img src="/img/baseline_cloud_download_white_48dp.png" :alt="$t('download')" :title="$t('download')" class="btn__img">{{ $t('download') }}
            </button>
          </div>
        </div>
        <div class="scrollable-cols --h70vh" v-if="reportData.campaigns && reportData.campaigns.length > 0">
          <table class="card__table" v-for="(campaign, campaignIndex) in reportData.campaigns" :key="campaignIndex">
            <colgroup>
              <template v-for="(type, name, columnIndex) in reportHeaders">
                <col :class="translateColClass(type, name)" :key="`${campaignIndex}-header-${columnIndex}`">
              </template>
            </colgroup>
            <thead>
              <tr>
                <template v-for="(type, name, columnIndex) in reportHeaders">
                  <th :class="translateColClass(type, name)" :key="columnIndex">
                    {{ getColumnsMeta(name).label }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr class="parent-row child-row--last-child">
                <td v-for="(type, name, columnIndex) in reportHeaders"
                  :key="`${campaignIndex}-value-${columnIndex}`"
                  :title="(type.toLowerCase() === 'string') ? translateColValue(campaign.values[name], type) : false"
                  :class="translateColClass(type, name)">{{ translateColValue(campaign.values[name], type) }}</td>
              </tr>
              <template v-for="(row, rowIndex) in campaign.daily">
                <tr class="child-row"
                  :key="`${campaignIndex}-daily-${rowIndex}`"
                  :class="{ 'child-row--last-child': campaign.daily.length === rowIndex + 1 }">
                  <td v-for="(type, name, columnIndex) in reportHeaders"
                    :key="`${campaignIndex}-daily-${rowIndex}-${columnIndex}`"
                    :title="(type.toLowerCase() === 'string') ? translateColValue(row[name], (name === 'name') ? 'date' : type) : false"
                    :class="translateColClass((name === 'name') ? 'namedate' : type, (name === 'name') ? 'namedate' : name)">{{ translateColValue(row[name], (name === 'name') ? 'date' : type) }}</td>
                </tr>
              </template>
            </tbody>
          </table>
          <table class="card__table" v-if="reportData.summary">
            <colgroup>
              <template v-for="(type, name, columnIndex) in reportHeaders">
                <col :class="translateColClass(type, name)" :key="`summary-header-${columnIndex}`">
              </template>
            </colgroup>
            <tbody>
              <tr class="child-row summary-row" v-for="(objectiveRow, summaryIndex) in reportData.summary" :key="summaryIndex">
                <td v-for="(type, name, columnIndex) in reportHeaders"
                  :key="`summary-${summaryIndex}-${columnIndex}`"
                  :title="(type.toLowerCase() === 'string') ? translateColValue(objectiveRow[name], type) : false"
                  :class="translateColClass(type, name)">{{ translateColValue(objectiveRow[name], type) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="scrollable-cols --h70vh" v-if="reportData.lineitems && reportData.lineitems.length > 0">
          <table class="card__table" v-for="(lineitem, lineitemIndex) in reportData.lineitems" :key="lineitemIndex">
            <colgroup>
              <template v-for="(type, name, columnIndex) in reportHeaders">
                <col :class="translateColClass(type, name)" :key="`${lineitemIndex}-header-${columnIndex}`">
              </template>
            </colgroup>
            <thead>
              <tr>
                <template v-for="(type, name, columnIndex) in reportHeaders">
                  <th :class="translateColClass(type, name)" :key="columnIndex">
                    {{ getColumnsMeta(name).label }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr class="parent-row">
                <td v-for="(type, name, columnIndex) in reportHeaders"
                  :key="`${lineitemIndex}-value-${columnIndex}`"
                  :title="(type.toLowerCase() === 'string') ? translateColValue(lineitem['values'][name], type) : false"
                  :class="translateColClass(type, name)">{{ translateColValue(lineitem['values'][name], type) }}</td>
              </tr>
              <template v-for="(campaign, campaignIndex) in lineitem.campaigns">
                <tr class="child-row"  :key="`${lineitemIndex}-campaign-${campaignIndex}`">
                  <td v-for="(type, name, columnIndex) in reportHeaders"
                    :key="`${campaignIndex}-value-${columnIndex}`"
                    :title="(type.toLowerCase() === 'string') ? translateColValue(campaign.values[name], type) : false"
                    :class="translateColClass(type, name)">{{ translateColValue(campaign.values[name], type) }}</td>
                </tr>
                <template v-for="(row, rowIndex) in campaign.daily">
                  <tr class="child-row"
                    :key="`${campaignIndex}-daily-${rowIndex}`"
                    :class="{ 'child-row--last-child': campaign.daily.length === rowIndex + 1 }">
                    <td v-for="(type, name, columnIndex) in reportHeaders"
                      :key="`${campaignIndex}-daily-${rowIndex}-${columnIndex}`"
                      :title="(type.toLowerCase() === 'string') ? translateColValue(row[name], (name === 'name') ? 'date' : type) : false"
                      :class="translateColClass((name === 'name') ? 'namedate' : type, (name === 'name') ? 'namedate' : name)">{{ translateColValue(row[name], (name === 'name') ? 'date' : type) }}</td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
          <table class="card__table" v-if="reportData.summary">
            <colgroup>
              <template v-for="(type, name, columnIndex) in reportHeaders">
                <col :class="translateColClass(type, name)" :key="`summary-header-${columnIndex}`">
              </template>
            </colgroup>
            <tbody>
              <tr class="child-row summary-row" v-for="(objectiveRow, summaryIndex) in reportData.summary" :key="summaryIndex">
                <td v-for="(type, name, columnIndex) in reportHeaders"
                  :key="`summary-${summaryIndex}-${columnIndex}`"
                  :title="(type.toLowerCase() === 'string') ? translateColValue(objectiveRow[name], type) : false"
                  :class="translateColClass(type, name)">{{ translateColValue(objectiveRow[name], type) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="scrollable-cols --h70vh" v-if="reportData.orders && reportData.orders.length > 0">
          <!-- TODO: NUEVA ESTRUCTURA [ ] orders -->
          ORDER
        </div>
      </div>
    </div>
    <div class="modal__actions">
      <div class="modal__actions--left">
      </div>
      <div class="modal__actions--right">
        <button type="button" class="btn btn--new" @click="goToDashboard">{{ $t('dashboard') }}</button>
      </div>
    </div>
  </main>
</template>
<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import {
  DOWNLOAD_REPORT,
  SHOW_MODAL,
  HIDE_MODAL
} from '@/core/action-types'
import i18n from '@/plugins/i18n'
export default {
  name: 'new-report',
  components: {},
  data: function () {
    return {
      pagination: {
        currentPage: 1,
        perPage: 40,
        totalPages: 1,
        total: 0
      },
      orderField: 'none',
      orderedTableItems: [],
      searchText: '',
      fieldsFilter: [],
      isFieldsSelectorVisible: false,
      isFieldsFilterActive: false,
      fieldsMeta: [
        {
          id: 'category',
          label: this.$t('category'),
          values: [
            { id: 'category-performance', label: this.$t('performance') },
            { id: 'category-billclosure', label: this.$t('bill-closure') }
          ]
        },
        {
          id: 'user',
          label: this.$t('user'),
          values: []
        }
      ],
      showFullWidthName: false,
      locale: i18n.locale,
      filteredReports: [],
      templateCategories: [
        { value: 'performance', label: this.$t('performance'), icon: '/img/baseline_speed_white_48dp.png' },
        { value: 'billclosure', label: this.$t('bill-closure'), icon: '/img/baseline_payments_white_48dp.png' }
      ],
      columnsMeta: [
        { id: 'bid_strategy', label: this.$t('bid-strategy'), format: '', sortable: false, class: '--w150px' },
        { id: 'budget', label: this.$t('budget'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'budget_remaining', label: this.$t('budget-remaining'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'budget_progress', label: this.$t('budget-progress'), format: 'decimal', sortable: false, class: '--w150px' },
        { id: 'cpr', label: this.$t('cpr'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'cpm', label: this.$t('cpm'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'ctr', label: this.$t('ctr'), format: 'decimal', sortable: false, class: '--w150px' },
        { id: 'cv_link_clicks', label: this.$t('clicks'), format: 'number', sortable: false, class: '--w150px' },
        { id: 'daily_target', label: this.$t('daily-target'), format: 'number', sortable: false, class: '--w150px' },
        { id: 'days_left', label: this.$t('days-left'), format: 'number', sortable: false, class: '--w150px' },
        { id: 'days_elapsed', label: this.$t('days-elapsed'), format: 'number', sortable: false, class: '--w150px' },
        { id: 'ecpr', label: this.$t('eCPR'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'eCPR_projected', label: this.$t('eCPR-projected'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'end_date', label: this.$t('end-date'), format: 'date', sortable: false, class: '--w150px' },
        { id: 'frequency', label: this.$t('frequency'), format: 'number', sortable: false, class: '--w150px' },
        { id: 'impressions', label: this.$t('impressions'), format: 'number', sortable: false, class: '--w150px' },
        { id: 'link_ctr', label: this.$t('ctr'), format: 'decimal', sortable: false, class: '--w150px' },
        { id: 'margin', label: this.$t('margin'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'model', label: this.$t('model'), format: '', sortable: false, class: '--w150px' },
        { id: 'name', label: this.$t('name'), format: '', sortable: false, class: '--w300px' },
        { id: 'pricing', label: this.$t('pricing'), format: '', sortable: false, class: '--w50px' },
        { id: 'objective', label: this.$t('objective'), format: '', sortable: false, class: '--w150px' },
        { id: 'profit', label: this.$t('profit'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'progress', label: this.$t('progress'), format: 'decimal', sortable: false, class: '--w150px' },
        { id: 'reach', label: this.$t('reach'), format: 'decimal', sortable: false, class: '--w150px' },
        { id: 'results', label: this.$t('results'), format: 'number', sortable: false, class: '--w150px' },
        { id: 'results_rate', label: this.$t('result-rate'), format: 'decimal', sortable: false, class: '--w150px' },
        { id: 'selling_cost', label: this.$t('spend'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'selling_cost_today', label: this.$t('selling-cost-today'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'spend', label: this.$t('buying-cost'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'spend_today', label: this.$t('spend-today'), format: 'currency', sortable: false, class: '--w150px' },
        { id: 'start_date', label: this.$t('start-date'), format: 'date', sortable: false, class: '--w150px' },
        { id: 'status', label: this.$t('status'), format: '', sortable: false, class: '--w150px' },
        { id: 'target_results', label: this.$t('target-results'), format: 'number', sortable: false, class: '--w300px' },
        { id: 'time_progress', label: this.$t('time-progress'), format: 'decimal', sortable: false, class: '--w150px' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'reportData'
    ]),
    filteredReportsTags () {
      return this.templates && this.templates.length > 0 && this.filteredReports && this.filteredReports.length > 0 ? this.filteredReports.map(templateId => {
        return {
          value: templateId,
          label: this.templates.find(template => template._id === templateId).name
        }
      }) : []
    },
    tabSavedReportsChecked () {
      return this.$route.params.tab === 'saved-reports'
    },
    tabCreateReportChecked () {
      return this.$route.params.tab === 'create-report'
    },
    fileUrl () {
      const url = this.reportData.url.split(`${process.env.VUE_APP_API}/reports`)[1]
      return `/storage/apps/reports/${url}`
    },
    fileName () {
      return `${this.reportData.name.toLowerCase()}.${this.fileUrl.split('.')[1]}`
    },
    reportDates () {
      let returnValue = ''
      if (this.reportData.start_date !== '' && this.reportData.end_date !== '') {
        returnValue = `${this.formatDate(this.reportData.start_date)} - ${this.formatDate(this.reportData.end_date)}`
      }
      return returnValue
    },
    reportHeaders () {
      let returnValue = []
      if (this.reportData) {
        if (this.reportData.campaigns) {
          returnValue = this.reportData.campaigns[0].headers
        }
        if (this.reportData.lineitems) {
          returnValue = this.reportData.lineitems[0].campaigns[0].headers
        }
        if (this.reportData.orders) {
          returnValue = this.reportData.orders[0].lineitems[0].campaigns[0].headers
        }
      }
      return returnValue
    }
  },
  mounted () {
    this.orderField = window.localStorage.getItem('orderField_new_report') || 'none'
    this.fieldsFilter = window.localStorage.getItem('fieldsFilter_new_report') ? window.localStorage.getItem('fieldsFilter_new_report').split(',') : []
  },
  watch: {
    $route (to, from) {
      this.fetchData()
    },
    orderField (newValue, oldValue) {
      window.localStorage.setItem('orderField_new_report', newValue)
    },
    fieldsFilter (newValue, oldValue) {
      window.localStorage.setItem('fieldsFilter_new_report', newValue.join(','))
      this.isFieldsFilterActive = true
    },
    templates (newValue, oldValue) {
      this.pagination.total = newValue.length
      this.pagination.totalPages = Math.ceil(newValue.length / this.pagination.perPage)
      this.pagination.currentPage = 1
      this.orderedTableItems = this.orderTableByField(newValue)
    }
  },
  methods: {
    ...mapActions({
      downloadReport: DOWNLOAD_REPORT,
      showModal: SHOW_MODAL,
      hideModal: HIDE_MODAL
    }),
    setCurrentPage (page) {
      this.pagination.currentPage = page
    },
    isRowVisible (index) {
      return index >= (this.pagination.currentPage - 1) * this.pagination.perPage && index < this.pagination.currentPage * this.pagination.perPage
    },
    orderTableByField (table) {
      const sortArray = function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      }
      const sortTextArray = function (a, b) {
        return a.localeCompare(b)
      }
      let sortedTable = (table) ? [...table] : []
      switch (this.orderField) {
        case 'name':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.name.toUpperCase(), b.name.toUpperCase()))
          break
        case 'user':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.role.toUpperCase(), b.role.toUpperCase()))
          break
        case 'created_at':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.start_date), new Date(b.start_date)))
          break
        case 'none':
          break
        default:
          sortedTable = sortedTable.sort((a, b) => sortArray(Number(a[this.orderField]), Number(b[this.orderField])))
      }
      return sortedTable
    },
    changeTableOrder (field) {
      this.orderField = field
      this.orderedTableItems = this.orderTableByField(this.templates)
    },
    isRowFiltered (row) {
      const filters = this.fieldsFilter.map(item => item.split('-'))
      let allFiltersPassed = {}
      filters.forEach(filter => { allFiltersPassed[filter[0]] = false })
      filters.forEach(filter => {
        if (row[filter[0]] === filter[1] || row[filter[0]] === Number(filter[1])) {
          allFiltersPassed[filter[0]] = true
        }
      })
      return Object.keys(allFiltersPassed).map(key => allFiltersPassed[key]).every(value => value === true)
    },
    hideReportData () {
      this.hideReport()
    },
    translateOptionsString (optionsObject) {
      return optionsObject ? Object.keys(optionsObject).map(item => `${this.$t(item)}${typeof optionsObject[item] === 'string' ? ` (${optionsObject[item]})` : ''}`).join(', ') : '-'
    },
    translateColClass (type, name) {
      let colClass = ''
      let stickyColumn = ''
      let colWidth = ''
      if (name === 'name' || name === 'namedate') {
        stickyColumn = 'sticky --left-1'
      } else if (name === 'objective') {
        stickyColumn = 'sticky --left-2 --left-300px'
      }
      switch (type.toLowerCase()) {
        case 'string':
          colWidth = (name === 'objective') ? '--w150px' : '--w300px'
          colClass = `${colWidth} ${stickyColumn}`
          break
        case 'date':
          colClass = '--ta-c --w150px'
          break
        case 'namedate':
          colClass = `--ta-c --w150px ${stickyColumn}`
          break
        case 'float':
        case 'percent':
        case 'int':
          colWidth = (name === 'target_results') ? '--w200px' : '--w100px'
          colClass = `--ta-r ${colWidth}`
          break
      }
      return colClass
    },
    translateColValue (value, type) {
      let colValue = ''
      if (value === undefined || value === null) {
        return ''
      } else if (value === 'N/A' || value === '') {
        return value
      } else {
        switch (type.toLowerCase()) {
          case 'string':
            let stringLength = 35
            colValue = value.length > stringLength ? value.substring(0, stringLength) + '...' : value
            break
          case 'date':
            colValue = this.formatDate(value)
            break
          case 'float':
            colValue = Number(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            break
          case 'int':
            colValue = Number(value).toLocaleString()
            break
        }
      }
      return colValue
    },
    translateSummaryColValue (value) {
      let colValue = ''
      switch (typeof value) {
        case 'number':
          colValue = Number(value).toLocaleString()
          break
        default:
          colValue = value
      }
      return colValue
    },
    formatDate (date) {
      return date && date !== '' ? new Date(date).toLocaleString(this.locale, { year: 'numeric', month: 'numeric', day: 'numeric' }) : ''
    },
    clearSearchText () {
      this.searchText = ''
    },
    getColumnsMeta (columnId) {
      return this.columnsMeta.find(column => column.id.toLowerCase() === columnId.toLowerCase())
    },
    fetchReport () {
      const url = this.reportData.url.split(`${process.env.VUE_APP_API}/reports`)[1]
      const fileFormat = url.split('.')[1]
      const onComplete = function (response) {
        let newBlob = new Blob([response.data])

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
        } else {
          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob)
          let link = document.createElement('a')
          link.href = data
          link.download = `${this.reportData.name}.${fileFormat}`
          link.setAttribute('download', `${this.reportData.name}.${fileFormat}`)
          link.click()
          window.setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
          }, 100)
          link.remove()
        }
      }
      this.downloadReport({ url, onComplete: onComplete.bind(this) })
    },
    goToDashboard () {
      let tab
      switch (this.$route.params.entiyType) {
        case 'campaign':
          tab = 'campaigns'
          break
        case 'lineitem':
          tab = 'planning'
          break
        default:
          tab = 'planning'
      }
      this.$router.push({ name: 'dashboard', params: { tab }, query: this.$route.query })
    },
    // EVENTS:
    fieldsFilterShowModal () {
      this.isFieldsSelectorVisible = true
    },
    onFieldsFilterHideModal () {
      this.isFieldsSelectorVisible = false
    },
    onFieldsFilterChange ({ fieldsFilter }) {
      this.fieldsFilter = [...fieldsFilter]
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
#reporting-view {
  padding: 2rem 2rem 100px;
  display: flex;
  flex-direction: column;
}
.card__header + .card--with-header {
  margin-bottom: 20px;
}
.dashboard-header {
  display: flex;
  line-height: 36px;
  justify-content: space-between;
  padding: 10px 20px;
}
.dashboard-header__breadcrumb {
  font-size: 24px;
  flex-basis: calc(100% - 650px);
  max-width: calc(100% - 660px);
  display: flex;
}
.dashboard-header__form {
  display: flex;
  position: relative;
}
#btn-clear-text {
  position: absolute;
  left: 240px;
}
.modal__actions {
  margin: 0;
}
</style>
