<template>
  <main id="reporting-view">
    <div class="dashboard-header">
      <p class="dashboard-header__breadcrumb">{{ $t('reports') }}</p>
      <form class="form dashboard-header__form" @submit.prevent>
        <input type="text" class="input__search input--white-bg" :placeholder="$t('search')" v-model="searchText">
        <button v-if="searchText" id="btn-clear-text" class="btn btn--clear-text" @click.prevent="clearSearchText">
          <img src="/img/baseline_clear_white_48dp.png" :alt="$t('clear')" :title="$t('clear')" class="btn__img btn__img--small">
        </button>
      </form>
    </div>
    <div class="tabs__tab-content-actions">
      <button class="btn btn--filter" :class="{ '--filter-active': isFieldsFilterActive }" @click.prevent="fieldsFilterShowModal">
        <img src="/img/baseline_filter_list_white_48dp.png" :alt="$t('filter')" :title="$t('filter')" class="btn__img btn__img--small">{{ $t('filter') }}
      </button>
      <!-- FIELDS-SELECTOR-MODAL -->
      <fields-selector
        id="fields-filter-selector"
        v-if="isFieldsSelectorVisible"
        :fieldsFilter="fieldsFilter"
        :fieldsMeta="fieldsMeta"
        v-on:on-fields-filter-hide-modal="onFieldsFilterHideModal"
        v-on:on-fields-filter-change="onFieldsFilterChange"></fields-selector>
    </div>
    <div class="card">
      <div class="card__content">
        <div class="card__header">
          <div class="card__header-text">
            <h4 class="card__title">{{ $t('team-reports', { team: teamName }) }}</h4>
          </div>
          <div class="card__header-actions">
            <button class="btn" @click="onDeleteReport" :disabled="!filteredReports || filteredReports.length !== 1">
              <img src="/img/baseline_delete_white_48dp.png" :alt="$t('delete')" :title="$t('delete')" class="btn__img">{{ $t('delete') }}
            </button>
          </div>
        </div>
        <div class="card__header" v-if="filteredReports.length > 0">
          <div class="card__header-text">
            <a v-for="(data, index) in filteredReportsTags" :key="index" href="#" class="tag tag--with-margin tag--collapsible" :title="$t('clear')" @click.prevent="clearTemplateTag(data)">
              {{ data.label }}
              <img class="btn__img btn__img--small --clear-cursor" src="/img/baseline_cancel_white_48dp.png" :alt="$t('clear')">
            </a>
          </div>
        </div>
        <div class="scrollable-cols">
          <table class="card__table">
            <colgroup>
              <col class="--w50px">
              <col class="--w600px">
              <col class="--w150px">
              <col class="--w150px">
              <col class="--w150px">
            </colgroup>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>
                  {{ $t('name') }}
                  <button class="btn btn--square--small" @click="changeTableOrder('name')">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === 'name' }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                  </button>
                </th>
                <th>
                  {{ $t('creation-date') }}
                  <button class="btn btn--square--small" @click="changeTableOrder('created_at')">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === 'created_at' }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                  </button>
                </th>
                <th>
                  {{ $t('entity') }}
                  <button class="btn btn--square--small" @click="changeTableOrder('entity_class')">
                    <img class="btn__img btn__img--small" :class="{ 'btn__img--active-filter': orderField === 'entity_class' }" src="/img/baseline_unfold_more_white_48dp.png" :alt="$t('sort')">
                  </button>
                </th>
                <th class="sticky --right">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(report, rowIndex) in reports.filter(item => item.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)">
                <tr
                  :key="`report-${report._id}`"
                  v-if="isRowFiltered(report)"
                  v-show="isRowVisible(rowIndex)"
                  :class="{ 'child-row--last-child': reports.length === rowIndex + 1 }">
                  <td>
                    <input type="checkbox" class="input__checkbox" :class="[ filteredReports.includes(report._id) ? 'input__checkbox--checked' : '' ]" :id="`report-id-${report._id}`" :name="`report-id-${report._id}`" :value="report._id" :checked="filteredReports.includes(report._id)" @change="onFilteredReportsChange">
                    <label class="input__label input__label--checkbox input__label--only--checkbox" :for="`report-id-${report._id}`"></label>
                  </td>
                  <td :title="report.name">
                    {{ report.name }}
                  </td>
                  <td :title="formatDate(report.created_at)">{{ formatDate(report.created_at) }}</td>
                  <td :title="`${$t(report.entity_class)}`">{{ $t(report.entity_class) }}</td>
                  <td class="--ta-r sticky --right">
                    <div class="table-cell-actions">
                      <button class="btn btn--hover" :title="$t('download')" @click.prevent="fetchReport(report)">
                        <img src="/img/baseline_cloud_download_white_48dp.png" class="btn__img">{{ $t('download') }}
                      </button>
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card__actions">
        <div class="card__actions--left">&nbsp;</div>
        <div class="card__actions--right">
          <div class="tfoot__rows-page"
            v-for="(page, index) in new Array(pagination.totalPages)"
            :key="index"
            :class="{ 'tfoot__current-page': pagination.currentPage - 1 === index }"
            @click="setCurrentPage(index + 1)">
            {{ 1 + (index * pagination.perPage) }} - {{ Math.min((index + 1) * pagination.perPage, pagination.total) }}</div>
          <div class="tfoot__total-rows">&nbsp;{{ $t('of') }}&nbsp;{{ pagination.total }}&nbsp;</div>
          <div class="tfoot__page-actions">
            <button class="btn btn--square--small" :disabled="pagination.currentPage === 1" @click="setCurrentPage(pagination.currentPage - 1)">
              <img class="btn__img" src="/img/baseline_chevron_left_white_48dp.png" :alt="$t('prev')">
            </button>
            <button class="btn btn--square--small" :disabled="pagination.currentPage === pagination.totalPages" @click="setCurrentPage(pagination.currentPage + 1)">
              <img class="btn__img" src="/img/baseline_chevron_right_white_48dp.png" :alt="$t('next')">
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import FieldsSelector from '@/core/components/FieldsSelector.vue'
import {
  mapGetters,
  mapActions
} from 'vuex'
import {
  SHOW_MODAL,
  HIDE_MODAL,
  DOWNLOAD_REPORT,
  GET_REPORTS,
  DELETE_REPORT
} from '@/core/action-types'
import i18n from '@/plugins/i18n'
export default {
  name: 'reports',
  components: {
    FieldsSelector
  },
  data: function () {
    return {
      pagination: {
        currentPage: 1,
        perPage: 40,
        totalPages: 1,
        total: 0
      },
      orderField: 'none',
      orderedTableItems: [],
      searchText: '',
      fieldsFilter: [],
      isFieldsSelectorVisible: false,
      isFieldsFilterActive: false,
      fieldsMeta: [
        {
          id: 'entity_class',
          label: this.$t('entity'),
          values: [
            { id: 'entity_class-lineitem', label: this.$t('line-item') },
            { id: 'entity_class-campaign', label: this.$t('campaign') }
          ]
        }
      ],
      locale: i18n.locale,
      filteredReports: []
    }
  },
  computed: {
    ...mapGetters([
      'authData',
      'reports'
    ]),
    filteredReportsTags () {
      return this.reports && this.reports.length > 0 && this.filteredReports && this.filteredReports.length > 0 ? this.filteredReports.map(templateId => {
        return {
          value: templateId,
          label: this.reports.find(template => template._id === templateId).name
        }
      }) : []
    },
    teamName () {
      return this.authData?.team?.name ?? ''
    }
  },
  mounted () {
    this.fetchData()
    this.orderField = window.localStorage.getItem('orderField_reports') || 'none'
    this.fieldsFilter = window.localStorage.getItem('fieldsFilter_reports') ? window.localStorage.getItem('fieldsFilter_reports').split(',') : []
  },
  watch: {
    $route (to, from) {
      this.fetchData()
    },
    orderField (newValue, oldValue) {
      window.localStorage.setItem('orderField_reports', newValue)
    },
    fieldsFilter (newValue, oldValue) {
      window.localStorage.setItem('fieldsFilter_reports', newValue.join(','))
      this.isFieldsFilterActive = true
    },
    reports (newValue, oldValue) {
      this.filteredReports = []
      this.pagination.total = newValue.length
      this.pagination.totalPages = Math.ceil(newValue.length / this.pagination.perPage)
      this.pagination.currentPage = 1
      this.orderedTableItems = this.orderTableByField(newValue)
    }
  },
  methods: {
    ...mapActions({
      showModal: SHOW_MODAL,
      hideModal: HIDE_MODAL,
      downloadReport: DOWNLOAD_REPORT,
      getReports: GET_REPORTS,
      deleteReport: DELETE_REPORT
    }),
    setCurrentPage (page) {
      this.pagination.currentPage = page
    },
    isRowVisible (index) {
      return index >= (this.pagination.currentPage - 1) * this.pagination.perPage && index < this.pagination.currentPage * this.pagination.perPage
    },
    orderTableByField (table) {
      const sortArray = function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      }
      const sortTextArray = function (a, b) {
        return a.localeCompare(b)
      }
      let sortedTable = (table) ? [...table] : []
      switch (this.orderField) {
        case 'name':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.name.toUpperCase(), b.name.toUpperCase()))
          break
        case 'entity_class':
          sortedTable = sortedTable.sort((a, b) => sortTextArray(a.entity_class.toUpperCase(), b.entity_class.toUpperCase()))
          break
        case 'created_at':
          sortedTable = sortedTable.sort((a, b) => sortArray(new Date(a.created_at), new Date(b.created_at)))
          break
        case 'none':
          break
        default:
          sortedTable = sortedTable.sort((a, b) => sortArray(Number(a[this.orderField]), Number(b[this.orderField])))
      }
      return sortedTable
    },
    changeTableOrder (field) {
      this.orderField = field
      this.orderedTableItems = this.orderTableByField(this.reports)
    },
    isRowFiltered (row) {
      const filters = this.fieldsFilter.map(item => item.split('-'))
      let allFiltersPassed = {}
      filters.forEach(filter => { allFiltersPassed[filter[0]] = false })
      filters.forEach(filter => {
        if (row[filter[0]] === filter[1] || row[filter[0]] === Number(filter[1])) {
          allFiltersPassed[filter[0]] = true
        }
      })
      return Object.keys(allFiltersPassed).map(key => allFiltersPassed[key]).every(value => value === true)
    },
    fetchData () {
      this.getReports()
    },
    formatDate (date) {
      return date && date !== '' ? new Date(date).toLocaleString(this.locale, { year: 'numeric', month: 'numeric', day: 'numeric' }) : ''
    },
    clearSearchText () {
      this.searchText = ''
    },
    fetchReport (report) {
      // CAMBIAR A ID + FORMAT
      const fileFormat = report?.options?.format ?? 'pdf'
      const url = `/${report._id}.${fileFormat}`
      const reportName = report.name
      const onComplete = function (response) {
        let newBlob = new Blob([response.data])

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
        } else {
          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob)
          let link = document.createElement('a')
          link.href = data
          link.download = `${reportName}.${fileFormat}`
          link.setAttribute('download', `${reportName}.${fileFormat}`)
          link.click()
          window.setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
          }, 100)
          link.remove()
        }
      }
      this.downloadReport({ url, onComplete: onComplete.bind(this) })
    },
    // EVENTS:
    onDeleteReport () {
      // TODO: revisar porqué falla
      const reportId = this.filteredReports[0]
      if (window.confirm(this.$t('sure-to-delete-report'))) {
        this.deleteReport({ id: reportId })
      }
    },
    onFilteredReportsChange (e) {
      this.filteredReports = e.target.checked
        ? [...this.filteredReports, e.target.value]
        : this.filteredReports.filter(item => item !== e.target.value)
    },
    fieldsFilterShowModal () {
      this.isFieldsSelectorVisible = true
    },
    onFieldsFilterHideModal () {
      this.isFieldsSelectorVisible = false
    },
    onFieldsFilterChange ({ fieldsFilter }) {
      this.fieldsFilter = [...fieldsFilter]
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
#reporting-view {
  padding: 2rem 2rem 100px;
  display: flex;
  flex-direction: column;
}
.card__header + .card--with-header {
  margin-bottom: 20px;
}
.dashboard-header {
  display: flex;
  line-height: 36px;
  justify-content: space-between;
  padding: 10px 20px;
}
.dashboard-header__breadcrumb {
  font-size: 24px;
  flex-basis: calc(100% - 650px);
  max-width: calc(100% - 660px);
  display: flex;
}
.dashboard-header__form {
  display: flex;
  position: relative;
}
#btn-clear-text {
  position: absolute;
  left: 240px;
}
</style>
